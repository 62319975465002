<template>
  <div class="aboutWrapper">
    <BackgroundThreeSquares></BackgroundThreeSquares>
    <div class="textWrapper">
      <h1>Schreiben Sie uns einfach eine</h1>
      <h1 class="colorfull">Nachricht.</h1>
    </div>
    <div class="mailWrapper">
      <lottie-player
        src="/lottie/lf20_gtc5sbre.json"
        background="transparent"
        speed="1"
        style="width: 100%; height: 100%"
        loop
        autoplay
      ></lottie-player>
    </div>
  </div>
</template>

<script>
import BackgroundThreeSquares from "@/components/Main/BackgroundThreeSquares.vue";

export default {
  name: "Kontakt",
  components: { BackgroundThreeSquares },
};
</script>

<style scoped lang="scss">
.aboutWrapper {
  position: relative;
  height: 100vh;
  width: 100%;
}

.textWrapper {
  h1 {
    font-size: 35pt;
    font-weight: 700;
    display: inline;
  }
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.mailWrapper {
  height: 30vh;
  position: absolute;
  right: 20%;
  top: 30vh;
  z-index: 1;
}

.mailWrapper > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1200px) and (min-width: 501px) {
  .textWrapper {
    bottom: 20%;
    h1 {
      font-size: 30pt;
    }
  }
  .mailWrapper {
    height: 35vh;
    top: 30%;
    left: 60%;
  }
  .formlabel {
    font-size: 12pt;
  }
}
@media only screen and (max-width: 500px) {
  .textWrapper {
    h1 {
      font-size: 20pt;
    }
    width: 70%;
    left: 60%;
  }
  .mailWrapper {
    height: 40vh;
    left: 50%;
    top: 30%;
  }
  .formlabel {
    font-size: 12pt;
  }

  .button-container {
    button {
      font-size: 15pt;
      width: 150px;
    }
  }
}
</style>
