<template>
  <NavBar></NavBar>
  <section data-section-name="home"><Home></Home></section>
  <section data-section-name="about"><About></About></section>
  <section data-section-name="technologien">
    <Technologien></Technologien>
  </section>
  <section data-section-name="projekte"><Projekte></Projekte></section>
  <section data-section-name="erfolg"><Erfolg></Erfolg></section>
  <section data-section-name="skills"><Skills></Skills></section>
  <section data-section-name="process" v-if="!this.$store.getters.mobileView">
    <Prozess></Prozess>
  </section>
  <section data-section-name="kontakt">
    <Kontakt></Kontakt>
  </section>
  <section data-section-name="form">
    <Form></Form>
  </section>
  <section data-section-name="impressum"><Impressum></Impressum></section>
</template>

<script>
import { onMounted } from "vue";
import Home from "@/components/Home/Home.vue";
import About from "@/components/About/About.vue";
import Technologien from "@/components/About/Technologien.vue";
import NavBar from "@/components/Main/NavBar.vue";
import Erfolg from "@/components/Prozess/Erfolg.vue";
import Prozess from "@/components/Prozess/Prozess.vue";
import Form from "@/components/Kontakt/Form.vue";
import Kontakt from "@/components/Kontakt/Kontakt.vue";
import Projekte from "@/components/Projekte/Projekte.vue";
import Skills from "@/components/Prozess/Skills.vue";
import Impressum from "@/components/impressum/Impressum.vue";

import { useStore } from "vuex";
export default {
  name: "Main",
  components: {
    Home,
    NavBar,
    About,
    Technologien,
    Erfolg,
    Form,
    Projekte,
    Skills,
    Kontakt,
    Prozess,
    Impressum,
  },
  setup() {
    const store = useStore();
    function handleView() {
      if (window.innerWidth <= 900) {
        store.dispatch("setMobileView", true);
      } else {
        store.dispatch("setMobileView", false);
      }
    }
    handleView();
    onMounted(() => window.addEventListener("resize", handleView));
    function changeToIndex(index, array) {
      const current = array[index][0].dataset.sectionName;
      if (
        current == "home" ||
        current == "about" ||
        current == "projekte" ||
        current == "erfolg" ||
        current == "kontakt" ||
        current == "technologien" ||
        current == "impressum"
      ) {
        store.dispatch("setcurrentPage", current);
      } else {
        changeToIndex(index - 1, array);
      }
    }
    onMounted(() => {
      window.$(function () {
        window.$.scrollify({
          section: "section",
          setHeights: false,
          before: (index, array) => {
            changeToIndex(index, array);
          },
        });
      });
    });
    return {
      a: "",
    };
  },
};
</script>

<style></style>
