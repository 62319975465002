<template>
  <div class="center">
    <div class="form__group field" v-if="shown == 0">
      <input
        type="input"
        class="form__field"
        placeholder="Name"
        name="name"
        id="name"
        v-model="nameV"
        required
        @keyup.enter="name()"
      />
      <span class="bar"></span>
      <label for="name" class="form__label">Mein Name ist...</label>
    </div>
    <div class="form__group field" v-if="shown == 1">
      <input
        type="input"
        class="form__field"
        v-model="emailV"
        placeholder="Email"
        name="email"
        id="email"
        required
        v-focus
        @keyup.enter="email()"
      />
      <span class="bar"></span>
      <label for="name" class="form__label">Meine Email ist...</label>
    </div>
    <div class="form__group field multine" v-if="shown == 2">
      <textarea
        type="input"
        class="form__field"
        v-model="messageV"
        placeholder="Nachricht"
        name="nachricht"
        id="nachricht"
        required
        v-focus
        cols="40"
        rows="5"
      ></textarea>
      <span class="bar"></span>
      <label for="name" class="form__label">Nachricht</label>
    </div>
    <div class="button-container" v-if="shown == 2">
      <button @click="submit"><span>Senden</span></button>
    </div>
    <div class="finish" v-if="shown == 3">
      <div class="textWrapper">
        <div class="colorfull">Vielen Dank für Ihre Nachricht</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "axios";

export default {
  name: "Form",
  components: {},
  setup() {
    const url = "/submitMessage";
    const shown = ref(0);
    const message = {};
    const nameV = ref(null);
    const emailV = ref(null);
    const messageV = ref(null);
    function next() {
      shown.value = shown.value + 1;
    }
    function name() {
      message.name = nameV.value;
      next();
    }
    function email() {
      message.email = emailV.value;
      next();
    }
    function submit() {
      message.message = messageV.value;
      axios.post(url, message).then(() => {
        next();
      });
    }
    return {
      shown,
      name,
      email,
      submit,
      nameV,
      emailV,
      messageV,
    };
  },
};
</script>

<style lang="scss" scoped>
$white: #ffffff;
$accent: var(--frostberry_two);

.center {
  display: flex;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 50%;
}

.form__group:focus-within::before {
  position: absolute;
  content: ">";
  top: 18px;
  right: 5px;
  font-size: 30px;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(
    to right,
    var(--frostberry_one),
    var(--frostberry_two),
    var(--frostberry_three)
  );
}

.multine {
  &:focus-within::before {
    content: "" !important;
  }
  .form__field {
    border-bottom: none !important;
  }
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid white;
  outline: 0;
  font-size: 1.7rem;
  color: white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 1s;
  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.7rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: var(--gray);
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(
      to right,
      var(--frostberry_one),
      var(--frostberry_two),
      var(--frostberry_three)
    );
    display: inline-block;
    font-weight: 700;
  }
  padding-bottom: 6px;
  font-weight: 700;
  border: 0;
}
/* reset input */
.form__field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}

.bar {
  position: relative;
  display: block;
  width: 100%;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
  background-image: linear-gradient(
    to right,
    var(--frostberry_two),
    var(--frostberry_three)
  );
}
.bar:after {
  right: 50%;
  background-image: linear-gradient(
    to right,
    var(--frostberry_one),
    var(--frostberry_two)
  );
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

.form__field:after {
  content: "";

  border: solid white;
  border-width: 0 3px 3px 0;
  padding: 3px;

  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
@media only screen and (max-width: 600px) {
  .form_group {
    width: unset;
  }
  .center {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
}

.button-container {
  left: 0;
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
}
.button-container {
  transition-delay: 0.7s;
}

.button-container {
  margin: 0 60px;
  text-align: center;

  button {
    outline: 0;
    cursor: pointer;
    position: relative;
    display: inline-block;
    background: 0;
    width: 240px;
    border: 2px solid #e3e3e3;
    padding: 20px 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.3s ease;

    span {
      position: relative;
      z-index: 1;
      color: #ddd;
      transition: 0.3s ease;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      background: $accent;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      margin: -15px 0 0 -15px;
      opacity: 0;
      transition: 0.3s ease;
    }

    &:hover,
    &:active,
    &:focus {
      border-color: $accent;

      span {
        color: $accent;
      }
    }

    &:active,
    &:focus {
      span {
        color: $white;
      }

      &:before {
        opacity: 1;
        transform: scale(10);
      }
    }
  }
}
.finish {
  font-size: 30pt;
}
</style>
