<template>
  <div class="nav-trigger" :class="{ open: navIsOpen }" @click="toggle">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
  <div class="NavBarWrapper" :class="{ wrapperOpen: navIsOpen }">
    <logo class="navBarLogo"></logo>
    <div class="navBarPageWrapper">
      <div
        class="pageLink"
        @click="moveTo('home')"
        :class="{ activeLink: 'home' == currentPage }"
      >
        <p>home</p>
      </div>
      <div
        class="pageLink"
        @click="moveTo('about')"
        :class="{
          activeLink: 'about' == currentPage || 'technologien' == currentPage,
        }"
      >
        <p>about</p>
      </div>
      <div
        class="pageLink"
        @click="moveTo('projekte')"
        :class="{ activeLink: 'projekte' == currentPage }"
      >
        <p>projekte</p>
      </div>
      <div
        class="pageLink"
        @click="moveTo('erfolg')"
        :class="{ activeLink: 'erfolg' == currentPage }"
      >
        <p>prozess</p>
      </div>
      <div
        class="pageLink"
        @click="moveTo('kontakt')"
        :class="{ activeLink: 'kontakt' == currentPage }"
      >
        <p>kontakt</p>
      </div>
      <div
        class="pageLink"
        @click="moveTo('impressum')"
        :class="{ activeLink: 'impressum' == currentPage }"
      >
        <p>impressum</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import Logo from "./Logo.vue";
import { useStore } from "vuex";

export default {
  components: { Logo },
  name: "NavBar",
  setup() {
    const store = useStore();
    const navIsOpen = ref(false);
    const navIsClosed = ref(true);
    function moveTo(name) {
      window.$.scrollify.move(`#${name}`);
      toggle();
    }
    const currentPage = computed(() => store.getters.currentPage);
    function toggle() {
      if (navIsOpen.value) {
        navIsOpen.value = false;
        navIsClosed.value = true;
      } else {
        navIsOpen.value = true;
        navIsClosed.value = false;
      }
    }
    return {
      moveTo,
      currentPage,
      toggle,
      navIsOpen,
      navIsClosed,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "compass/css3";

$nav-width: 40px;
$nav-height: 6px;
$nav-width-open: 42px;
$nav-height-open: 8px;
$frostberry_one: #6900ff;
$frostberry_two: #3fa8f4;
$frostberry_three: #00ffff;

@mixin nav-ease() {
  @include ease-in-out-expo;
  //@include ease-in-out-back;
}

@mixin ease-in-out-expo() {
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -moz-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -ms-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -o-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

@mixin ease-in-out-back() {
  -webkit-transition-timing-function: cubic-bezier(
    0.68,
    0,
    0.265,
    1
  ); /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -moz-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -ms-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(
    0.68,
    -0.55,
    0.265,
    1.55
  ); /* easeInOutBack */
}

.line {
  background: white;
}

.nav-trigger {
  position: fixed;
  display: none;
  top: 70px;
  right: 40px;
}

.nav-trigger {
  cursor: pointer;
  z-index: 200;
  @include transition((transform 300ms ease));

  .line {
    width: $nav-width;
    height: $nav-height;
    margin-bottom: $nav-height;
    background: white;

    @include transition((all 300ms ease));
    @include nav-ease;
    @include transform-origin(50%, 50%);

    &:first-child {
      @include transform-origin(0, 0);
    }
    &:last-child {
      @include transform-origin($nav-width, $nav-height);
    }
  }

  &:hover {
    .line {
      background-image: radial-gradient(
        circle,
        $frostberry_one,
        $frostberry_two,
        $frostberry_three
      );
    }
  }

  &.open {
    $size: $nav-height-open;
    $half-size: floor($size / 2);
    $quarter-size: floor($size / 4);
    $rest: ($size - $quarter-size * 4) / 2;

    $center-x: ($nav-width - $nav-width-open) / 2;
    $center-y: (($nav-height - $nav-height-open) * 5) / 2;
    @include transform(
      (translate(0 - $quarter-size + $center-x, 0 - $quarter-size + $center-y))
    );

    .line {
      width: $nav-width-open;
      height: $nav-height-open;
      margin-bottom: $nav-height-open;
      @include transform(scale(0));

      &:first-child {
        @include transform(
          (
            rotate(405deg)
              translate($size + $quarter-size + 1px, $size + $half-size + $rest)
          )
        );
        @include transform-origin(50%, $size);
      }

      &:last-child {
        @include transform(
          (
            rotate(-405deg)
              translate($size + $quarter-size, 0 - ($size + $quarter-size))
          )
        );
        @include transform-origin(50%, 0);
      }
    }
  }
}

#btnControl {
  display: none;
}
#button {
  display: none;
  margin-top: 1px;
  float: right;
}
.img_btn {
  width: 40px !important;
  height: auto !important;
}
.NavBarWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100vh;
  padding-left: 30px;
  z-index: 5000;
}

.navBarLogo {
  margin-top: 10px;
  width: 100%;
}

.navBarPageWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30vh;
  margin-top: 20vh;
  margin-left: 30px;
}

.pageLink {
  color: white;
  width: 100%;
  font-size: 15pt;
  margin-top: 5px;
  cursor: pointer;
}

.pageLink > p {
  background-image: linear-gradient(
    to right,
    var(--frostberry_one),
    var(--frostberry_two),
    var(--frostberry_three) 50%,
    white 50%
  );
  background-size: 200%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  display: inline-block;
  background-position: -100%;
  transition: all 1s;
  font-weight: 700;
}

.pageLink > p:hover {
  animation: moveToLeft 0.5s forwards;
  -webkit-animation: moveToLeft 0.5s forwards;
}

@-webkit-keyframes moveToLeft {
  100% {
    background-position: -200%;
  }
}

@keyframes moveToLeft {
  100% {
    background-position: -200%;
  }
}

.activeLink > p {
  background-position: -200%;
  margin-left: 20px;
}
@media only screen and (max-width: 600px) {
  .NavBarWrapper {
    transform: translate(-100%);
    background-color: #191919;
    transition: transform 1s ease;
    padding-left: 0;
  }
  .navBarPageWrapper {
    padding-left: 30px;
  }
  .nav-trigger {
    display: block;
  }

  .navBarLogo {
    margin: 0 auto;
  }
}

.wrapperOpen {
  transform: translate(0);
}
</style>
