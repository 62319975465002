<template>
  <div class="aboutWrapper">
    <BackgroundThreeSquares></BackgroundThreeSquares>
    <div class="impressumWrapper">
      <div class="slide-title">
        <h1>Impressum</h1>
      </div>
      <div class="slide">
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          frostberry UG (haftungsbeschr&auml;nkt)
          <br />
          Frankenweg 37
          <br />
          97318 Kitzingen
        </p>

        <p>
          Handelsregister: HRB 15679
          <br />
          Registergericht: Amtsgericht W&uuml;rzburg
        </p>

        <p>
          <strong>Vertreten durch:</strong>
          <br />
          Namir Sacic
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: +49 1525 4086098
          <br />
          E-Mail: namir.sacic@frostberry.de
        </p>

        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
          Umsatzsteuergesetz:
          <br />
          DE343761259
        </p>

        <h2>Redaktionell verantwortlich</h2>
        <p>
          Namir Sacic
          <br />
          Frankenweg 37
          <br />
          97318 Kitzingen
        </p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr/
          </a>
          .
          <br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir nehmen an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teil. Zust&auml;ndig ist die
          Universalschlichtungsstelle des Zentrums f&uuml;r Schlichtung e.V.,
          Stra&szlig;burger Stra&szlig;e 8, 77694 Kehl am Rhein (
          <a
            href="https://www.verbraucher-schlichter.de"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.verbraucher-schlichter.de
          </a>
          ).
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundThreeSquares from "@/components/Main/BackgroundThreeSquares.vue";
export default {
  name: "Impressum",
  components: { BackgroundThreeSquares },
};
</script>
<style lang="scss" scoped>
.aboutWrapper {
  position: relative;
  height: 100vh;
  width: 100%;
}

.impressumWrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1200px) and (min-width: 201px) {
  .textWrapper {
    bottom: 20%;
    font-size: 30pt;
    margin-right: 0px;
    text-align: center;
  }
}
@media only screen and (max-width: 500px) {
  .slide-title {
    width: 80vw;
  }
  .slide {
    width: 80vw;
    overflow: scroll;
  }
  .textWrapper {
    font-size: 20pt;
    width: 60%;
  }
}

.slide-title {
  background-image: linear-gradient(
    to bottom,
    var(--frostberry_one),
    var(--frostberry_two) 80%
  );
  margin-top: 15vh;
  border-radius: 20px 20px 0px 0px;
  padding: 20px;
  width: 60vw;
}
.slide {
  h1 {
    font-size: 0.9em;
  }

  h2 {
    font-size: 0.7em;
  }
  p {
    font-size: 0.65em;
  }
  background-color: black;
  position: relative;
  border-radius: 0px 0px 20px 20px;
  padding: 20px;
  width: 60vw;
  min-height: 50vh;
  max-height: 70vh;
}

@media only screen and (max-width: 500px) {
  .slide-title {
    width: 80vw;
  }
  .slide {
    width: 80vw;
    overflow: scroll;
  }
  .impressumWrapper {
    left: 50%;
  }
}
</style>
