<template>
  <div class="aboutWrapper">
    <BackgroundThreeSquares></BackgroundThreeSquares>
    <div class="processSwiperContainer">
      <swiper :breakpoints="swiperOpt.breakpoints" v-bind="swiperOpt">
        <swiper-slide v-for="(element, index) in elements" :key="index">
          <div class="processSlide">
            <div class="number">{{ index + 1 }}</div>
            <div class="text">{{ element }}</div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import BackgroundThreeSquares from "@/components/Main/BackgroundThreeSquares.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
export default {
  name: "Prozess",
  components: { BackgroundThreeSquares, Swiper, SwiperSlide },
  setup() {
    const swiperOpt = {
      spaceBetween: 10,
      loop: false,
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        800: {
          slidesPerView: 3,
        },
        1000: {
          slidesPerView: 5,
        },
      },
    };
    const elements = [
      "Briefing Analyse Beratung",
      "Konzeption & Lösungsansätze",
      "Umsetzung, Betreuung & Lösung",
      "Go-Live des fertigen Produkts",
      "Full-Service Pflege & Beratung",
    ];
    return {
      swiperOpt,
      elements,
    };
  },
};
</script>

<style lang="scss" scoped>
.aboutWrapper {
  position: relative;
  height: 100vh;
  width: 100%;
}
.processSwiperContainer {
  position: absolute;
  left: 25%;
  top: 50vh;
  transform: translate(0, -50%);
}
.processSlide {
  position: relative;
  height: 230px;
  width: 150px;
  padding: 15px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    padding: 2px;
    background: linear-gradient(
      to bottom,
      var(--frostberry_one),
      var(--frostberry_two) 80%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  .number {
    font-family: "Neon";
    font-size: 75pt;
    text-align: center;
    margin-top: 20px;
  }
  .text {
    font-weight: 700;
    font-size: 13pt;
    text-align: center;
  }
}

swiper-slide {
  padding: 5px;
}
</style>
