<template>
  <div class="aboutWrapper">
    <BackgroundThreeSquares v-if="!mobileView"></BackgroundThreeSquares>
    <div class="tagContainer"></div>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import TagCloud from "TagCloud";
import BackgroundThreeSquares from "@/components/Main/BackgroundThreeSquares.vue";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "Skills",
  components: { BackgroundThreeSquares },
  setup() {
    const store = useStore();
    const myTags = [
      "JavaScript",
      "CSS",
      "HTML",
      "C",
      "C++",
      "React",
      "Angular",
      "Python",
      "Java",
      "git",
      "Vue.js",
      "Node.js",
      "MySQL",
      "jQuery",
      "KI.AI",
      "Machine Learning",
      "Wordpress",
      "Google Cloud",
      "Azure",
      "AWS",
      "Linux",
    ];
    const mobileView = computed(() => {
      return store.getters.mobileView;
    });
    onMounted(() => {
      if (mobileView.value) {
        TagCloud(".tagContainer", myTags, {
          radius: 150,
          maxSpeed: "fast",
          initSpeed: "fast",
          direction: 135,
          keep: false,
        });
      } else {
        TagCloud(".tagContainer", myTags, {
          radius: 400,
          maxSpeed: "fast",
          initSpeed: "fast",
          direction: 135,
          keep: false,
        });
      }
    });
    return {
      mobileView,
    };
  },
};
</script>

<style lang="scss">
.aboutWrapper {
  position: relative;
  height: 100vh;
  width: 100%;
}

.tagContainer {
  margin-left: 50%;
  transform: translate(-55%, -5%);
  max-height: 100vh;
  font-weight: 700;
}

.tagcloud {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  margin: auto;
  width: 50%;
}

.tagcloud--item:hover {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(
    to right,
    var(--frostberry_one),
    var(--frostberry_two),
    var(--frostberry_three)
  );
  display: inline-block;
}
@media only screen and (max-width: 500px) {
  .tagContainer {
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .tagcloud {
    font-size: 17pt;
  }
}
</style>
