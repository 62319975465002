<template>
  <div class="technologyWrapper">
    <BackgroundThreeSquares></BackgroundThreeSquares>
    <div class="technologyInnerWrapper">
      <div class="textWrapper">
        <h1>Wir verwenden neuste Technologien bei der</h1>
        <h1 class="colorfull">Entwicklung.</h1>
      </div>
      <lottie-player
        ref="lottiePlayer"
        src="/lottie/lf20_dztvflwp.json"
        background="transparent"
        speed="1"
        style="width: 100%; height: 100%"
        v-if="!mobileView"
      ></lottie-player>
      <div class="threeThingsWrapper" v-if="!mobileView && showThreeThings">
        <div v-for="(text, index) in texts" :key="index">{{ text }}</div>
      </div>
      <div class="technologienSwiperContainer" v-if="mobileView">
        <swiper v-bind="SwiperOption">
          <swiper-slide v-for="(text, index) in texts" :key="index">
            {{ text }}
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundThreeSquares from "@/components/Main/BackgroundThreeSquares.vue";
import { ref, watch, computed, nextTick } from "vue";
import { useStore } from "vuex";
import SwiperCore, { Virtual, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Virtual, Autoplay]);

export default {
  name: "Technologien",
  components: { BackgroundThreeSquares, Swiper, SwiperSlide },
  setup() {
    const SwiperOption = {
      slidesPerView: 2,
      spaceBetween: 10,
      autoplay: {
        delay: 2000,
      },
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
    };
    const lottiePlayer = ref(null);
    const store = useStore();
    const showThreeThings = ref(false);
    const texts = [
      "Wir bieten individuelle Webentwicklung mithilfe neuester Technologien und aktuellsten Standards für Ihr Unternehmen.",
      "Wir beherrschen alle gängigen Programmiersprachen und Anwendungen auf fast alle Systeme, wie Shop, IT, Verwaltung uvm.",
      "Wir machen Ihre Idee mobil. Egal welches System oder Format, wir  finden die optimale Lösung für Endgeräte.",
    ];
    const current = computed(() => {
      return store.getters.currentPage;
    });
    const mobileView = computed(() => {
      return store.getters.mobileView;
    });
    watch(current, () => {
      if (current.value == "technologien" && !mobileView.value) {
        lottiePlayer.value.play();
        if (!showThreeThings.value) {
          showThreeThings.value = false;
          nextTick(() => {
            showThreeThings.value = true;
          });
        }
      }
    });
    return {
      lottiePlayer,
      mobileView,
      texts,
      SwiperOption,
      showThreeThings,
    };
  },
};
</script>

<style scoped lang="scss">
.technologyWrapper {
  position: relative;
  height: 100vh;
  width: 100%;
}

.textWrapper {
  h1 {
    font-size: 35pt;
    font-weight: 700;
    display: inline;
  }
}

.technologyInnerWrapper {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.technologyInnerWrapper > img {
  object-fit: fill;
  height: 20vh;
  width: 100%;
}

@media only screen and (max-width: 1200px) and (min-width: 201px) {
  .textWrapper {
    bottom: 20%;
    h1 {
      font-size: 30pt;
    }
    text-align: center;
  }
}
@media only screen and (max-width: 500px) {
  .textWrapper {
    h1 {
      font-size: 20pt;
    }
  }
  .technologyInnerWrapper {
    top: 50%;
    width: 60%;
  }
  .technologyInnerWrapper > img {
    height: 10vh;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.threeThingsWrapper {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 135%;
  transform: translate(-13%);
  font-weight: 500;
  :nth-child(1) {
    animation-delay: 0.5s;
  }
  :nth-child(2) {
    animation-delay: 1s;
  }
  :nth-child(3) {
    animation-delay: 1.6s;
  }
  div {
    opacity: 0;
    width: 30%;
    text-align: center;
    -webkit-animation: fadeIn forwards;
    animation: fadeIn forwards;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
  }
}

.technologienSwiperContainer {
  padding-top: 10px;
  text-align: center;
}
</style>
