<template>
  <div class="aboutWrapper">
    <BackgroundThreeSquares></BackgroundThreeSquares>
    <div class="erfolgWrapper">
      <div class="textWrapper">
        <h1>
          Ihr Erfolg durch
          <br />
          innovative
        </h1>
        <br />

        <h1 class="colorfull">Technologien.</h1>
      </div>
      <div class="lottieWrapper">
        <lottie-player
          ref="lottiePlayer"
          src="/lottie/lf20_regtdlgn.json"
          background="transparent"
          speed="1"
          style="width: 100%; height: 100%"
        ></lottie-player>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundThreeSquares from "@/components/Main/BackgroundThreeSquares.vue";
import { useStore } from "vuex";
import { computed, watch, ref } from "vue";
export default {
  name: "Erfolg",
  components: { BackgroundThreeSquares },
  setup() {
    const lottiePlayer = ref(null);
    const store = useStore();
    const current = computed(() => {
      return store.getters.currentPage;
    });
    watch(current, () => {
      if (current.value == "erfolg") {
        lottiePlayer.value.play();
      }
    });
    return {
      lottiePlayer,
    };
  },
};
</script>

<style scoped lang="scss">
.aboutWrapper {
  position: relative;
  height: 100vh;
  width: 100%;
}
.lottieWrapper {
  height: 300px;
  position: absolute;
}
.erfolgWrapper {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textWrapper {
  h1 {
    font-size: 35pt;
    font-weight: 700;
    display: inline;
  }
  z-index: 5;
  margin-bottom: 200px;
  margin-right: 250px;
}
@media only screen and (max-width: 1200px) and (min-width: 201px) {
  .textWrapper {
    bottom: 20%;
    h1 {
      font-size: 30pt;
    }
    margin-right: 0px;
    text-align: center;
  }
}
@media only screen and (max-width: 500px) {
  .textWrapper {
    h1 {
      font-size: 20pt;
    }
    width: 60%;
  }
}
</style>
