<template>
  <div class="projektWrapper">
    <div class="container">
      <div class="box">
        <div class="content">
          <img src="@/assets/img/projects/aogreen.jpg" />
          <p>AoGreen Windanalysetool</p>
        </div>
      </div>
      <div class="box">
        <div class="content">
          <img src="@/assets/img/projects/prototype.png" />
          <p>weitere Projekte</p>
        </div>
      </div>
      <div class="box">
        <div class="content">
          <img src="@/assets/img/projects/fotogoals.png" />
          <p>fotogoals App</p>
        </div>
      </div>
      <div class="box">
        <div class="content">
          <img src="@/assets/img/projects/EEV_Logo_white.svg" />
          <p>Ecoterre webshop</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Projekte",

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.projektWrapper {
  position: relative;
}
.container {
  position: absolute;
  left: 20%;
  max-width: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: minmax(100px, auto);
  margin: 40px;
  margin-top: 10%;
  grid-auto-flow: dense;
  grid-gap: 10px;
}
.container .box {
  background: #333;
  padding: 20px;
  display: grid;
  font-size: 20px;
  place-items: center;
  text-align: center;
  color: #fff;
  transition: 0.5s;
}
.container .box:hover {
  background: var(--frostberry_two);
}
.container .box img {
  position: relative;
  margin-bottom: 10px;
  max-height: 100px;
}
.container .box:nth-child(1) {
  grid-column: span 2;
  grid-row: span 1;
}
.container .box:nth-child(2) {
  grid-column: span 1;
  grid-row: span 3;
}
.container .box:nth-child(4) {
  grid-column: span 1;
  grid-row: span 1;
}
.container .box:nth-child(5) {
  grid-column: span 1;
  grid-row: span 1;
}

@media (max-width: 991px) {
  .container {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    grid-template-rows: minmax(auto, auto);
    left: 0px;
  }
  .container .box {
    grid-column: unset !important;
    grid-row: unset !important;
  }
}
</style>
