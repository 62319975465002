<template>
  <div>
    <lottie-player
      v-if="main"
      src="/lottie/lf20_3x5piaux.json"
      background="transparent"
      speed="1"
      style="width: 100%; height: 100%"
      loop
      autoplay
    ></lottie-player>
    <lottie-player
      v-if="!main"
      src="/lottie/lf20_mp7vq3sy.json"
      background="transparent"
      speed="1"
      style="width: 100%; height: 100%; opacity: 0.3"
      loop
      autoplay
    ></lottie-player>
  </div>
</template>

<script>
export default {
  name: "BackgroundThreeSquares",
  props: {
    main: Boolean,
  },
};
</script>

<style scoped>
div {
  position: absolute;
  right: -5%;
  top: 5vh;
  height: 80vh;
  width: 40%;
  z-index: 0;
}
@media only screen and (max-width: 1200px) and (min-width: 201px) {
  div {
    right: -2%;
  }
}
</style>
