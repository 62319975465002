<template>
  <div class="HomeWrapper">
    <Logo class="background_Logo"></Logo>
    <BackgroundThreeSquares :main="true"></BackgroundThreeSquares>
  </div>
</template>

<script>
import Logo from "@/components/Main/Logo.vue";
import BackgroundThreeSquares from "@/components/Main/BackgroundThreeSquares.vue";
export default {
  name: "Home",
  components: {
    Logo,
    BackgroundThreeSquares,
  },
};
</script>

<style scoped>
.HomeWrapper {
  position: relative;
  height: 100vh;
  width: 100%;
}
.background_Logo {
  position: absolute;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
@media only screen and (max-width: 1200px) and (min-width: 501px) {
  .background_Logo {
    top: 40%;
  }
}
@media only screen and (max-width: 500px) {
  .background_Logo {
    width: 250px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
