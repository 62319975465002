<template>
  <div class="aboutWrapper">
    <BackgroundThreeSquares></BackgroundThreeSquares>
    <div class="textWrapper">
      <h1>Wir sind ein kreatives Startup im Bereich</h1>
      <h1 class="colorfull">Software.</h1>
    </div>
    <div class="rocketWrapper">
      <lottie-player
        src="/lottie/lf20_ymuusxbu.json"
        background="transparent"
        speed="1"
        style="width: 100%; height: 100%"
        loop
        autoplay
      ></lottie-player>
    </div>
  </div>
</template>

<script>
import BackgroundThreeSquares from "@/components/Main/BackgroundThreeSquares.vue";

export default {
  name: "About",
  components: { BackgroundThreeSquares },
};
</script>
<style lang="scss" scoped>
.aboutWrapper {
  position: relative;
  height: 100vh;
  width: 100%;
}

.textWrapper {
  h1 {
    font-size: 35pt;
    font-weight: 700;
    display: inline;
  }
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.rocketWrapper {
  height: 75vh;
  position: absolute;
  right: 19%;
  top: 10vh;
  z-index: 1;
}

.rocketWrapper > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1200px) and (min-width: 501px) {
  .textWrapper {
    bottom: 20%;
    h1 {
      font-size: 30pt;
    }
  }
  .rocketWrapper {
    height: 35vh;
    top: 30%;
    left: 60%;
  }
}
@media only screen and (max-width: 500px) {
  .textWrapper {
    h1 {
      font-size: 20pt;
    }
    width: 80%;
    text-align: center;
  }
  .rocketWrapper {
    height: 40vh;
    left: 50%;
    top: 30%;
  }
}
</style>
