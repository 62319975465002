<template>
  <div>
    <img
      src="@/assets/img/logo/frostberry_4C_negativ.svg"
      alt="frostberry Logo"
    />
  </div>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style scoped>
img {
  height: 100%;
  width: 100%;
}
</style>
